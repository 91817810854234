import type { HTMLProps, PropsWithChildren } from "react";

interface CardProps extends HTMLProps<HTMLDivElement> {
  selected?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
}

export const Card = ({
  children,
  selected,
  type = "button",
  className,
  ...rest
}: PropsWithChildren<CardProps>) => {
  return (
    <div
      className={`border-solid border-2  relative flex-grow bg-gray-800/75 w-fit rounded-2xl h-fit m-auto flex flex-row p-2 lg:p-4 border-gray-800/50 ${
        selected ? "border-lime-500/60" : "border-gray-800/50"
      } ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};
